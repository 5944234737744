<template>
  <div>
    <b-container name="mainContainer">
      <b-row class="mt-1">
        <b-col md="6">
          <b-link :to="{ name: 'home' }">
            <b-row cols-md="4">
              <b-col md="2">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                  border="solid"
                />
              </b-col>
              <b-col
                md="10"
                align-self="center"
              >
                <b-row>
                  <h2 class="text-primary">
                    {{ appNameLong }}
                  </h2>
                </b-row>
              </b-col>
            </b-row>
          </b-link>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="12">
          <router-view />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  BContainer, BRow, BCol, BLink, BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  setup() {
    const { appLogoImage, appNameLong } = $themeConfig.app
    return { appLogoImage, appNameLong }
  },
}
</script>
